<template>
  <EmployeeAdminTable
    :items="items"
    :is-busy="isBusyStore"
    :pagination="pagination"
    :is-super-admin="isSuperAdmin"
    @changePage="handlePageChange"
    @assign="assignEmployeeAdmin"
    @delete="deleteEmployeeAdmin"
    @transfer="transferSuperAdmin"
  ></EmployeeAdminTable>
</template>

<script>
import EmployeeAdminTable from "@/modules/school/components/shared/employee/table/EmployeeAdminTable";
import { mapGetters } from "vuex";
export default {
  name: "TeacherAdminPageTable",
  components: { EmployeeAdminTable },
  props: ["reload"],
  data() {
    return {
      isBusy: false,
      type: 1,
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.isBusy = true;
      return this.$store
        .dispatch("fetchTeacherAdminList")
        .then((response) => {
          console.log("Received", response);
          return response.data.data;
        })
        .finally(() => (this.isBusy = false));
    },

    handlePageChange(page) {
      this.$store.commit("setTeacherAdminListPage", page);
      this.fetch();
    },

    assignEmployeeAdmin(row) {
      console.log("Selected: ", row.employee.first_name);

      this.$bvModal
        .msgBoxConfirm(
          `This will assign ${row.employee.first_name} admin role`,
          {
            title: this.$t("ALERT.SURE"),
            centered: true,
            noCloseOnBackdrop: true,
            cancelVariant: "light",
          }
        )
        .then((value) => {
          console.log("Hmmm", value);
          if (value) {
            return new Promise((resolve, reject) => {
              this.$store
                .dispatch("assignTeacherAdminRole", {
                  id: row.employee.id,
                })
                .then(() => {
                  this.$bvModal.msgBoxOk("Success!!", {
                    centered: true,
                  });
                  this.fetch();
                  resolve(value);
                })
                .catch(() => {
                  console.error("Some issues ");
                  this.$bvModal.msgBoxOk("Failed!!", {
                    centered: true,
                    okVariant: "danger",
                  });
                  reject(value);
                });
            });
          }
        })
        .catch((err) => {
          console.error("Some issues bro", err);
        });
    },

    deleteEmployeeAdmin(row) {
      console.log("Selected: ", row.employee.first_name);

      this.$bvModal
        .msgBoxConfirm(
          `This will delete ${row.employee.first_name} admin role`,
          {
            title: this.$t("ALERT.SURE"),
            centered: true,
            noCloseOnBackdrop: true,
            cancelVariant: "light",
          }
        )
        .then((value) => {
          console.log("Hmmm", value);
          if (value) {
            return new Promise((resolve, reject) => {
              this.$store
                .dispatch("removeTeacherAdminRole", {
                  id: row.employee.id,
                })
                .then(() => {
                  this.$bvModal.msgBoxOk("Success!!", {
                    centered: true,
                  });
                  this.fetch();
                  resolve(value);
                })
                .catch(() => {
                  console.error("Some issues ");
                  this.$bvModal.msgBoxOk("Failed!!", {
                    centered: true,
                    okVariant: "danger",
                  });
                  reject(value);
                });
            });
          }
        })
        .catch((err) => {
          console.error("Some issues bro", err);
        });
    },

    transferSuperAdmin(row) {
      console.log("Selected: ", row.employee.first_name);

      this.$bvModal
        .msgBoxConfirm(
          `Are you sure to assign ${row.employee.first_name}
           as super admin for company? If agree, you will be demoted to company admin`,
          {
            title: `Assign ${row.employee.first_nam} as Super Admin?`,
            centered: true,
            noCloseOnBackdrop: true,
            cancelVariant: "light",
          }
        )
        .then((value) => {
          console.log("Hmmm", value);
          if (value) {
            return new Promise((resolve, reject) => {
              this.$store
                .dispatch("transferTeacherSuperAdminRole", {
                  id: row.employee.id,
                })
                .then(() => {
                  this.$bvModal.msgBoxOk("Success!!", {
                    centered: true,
                  });
                  this.fetch();
                  resolve(value);
                })
                .catch(() => {
                  console.error("Some issues ");
                  this.$bvModal.msgBoxOk("Failed!!", {
                    centered: true,
                    okVariant: "danger",
                  });
                  reject(value);
                });
            });
          }
        })
        .catch((err) => {
          console.error("Some issues bro", err);
        });
    },
  },
  watch: {
    reload() {
      this.fetch();
    },
  },
  computed: {
    ...mapGetters({
      items: "getTeacherAdminList",
      pagination: "getTeacherAdminPagination",
      currentPage: "getTeacherAdminListPage",
      isSuperAdmin: "getSuperAdminPermission",
      isBusyStore: "getTeacherAdminListBusy",
    }),
  },
};
</script>

<style scoped></style>
