import { render, staticRenderFns } from "./TeacherAdminPageTableControl.vue?vue&type=template&id=426822ea&scoped=true&"
import script from "./TeacherAdminPageTableControl.vue?vue&type=script&lang=js&"
export * from "./TeacherAdminPageTableControl.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "426822ea",
  null
  
)

export default component.exports